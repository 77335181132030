<template>
  <div>
    <div v-if="loader" class="d-flex justify-content-center mb-3">
      <b-button variant="flat-primary" disabled class="mr-1">
        <b-spinner small />
        Chargement des statistiques...
      </b-button>
    </div>
    <div v-else>

      <b-form-datepicker
              id="from"
              reset-button
              v-model="filter.from"
              class="mb-1"
              placeholder="Jour"
              @input="getday"
            />
      <b-row>
        <b-col cols="12">
          <b-table
            :items="statistiques.engagements"
            :fields="fields"
            class="clear"
          >
            
          </b-table>

        </b-col>
      </b-row>

     
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import vSelect from "vue-select";
import { BFormDatepicker } from "bootstrap-vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BTable,
  BButton,
  BRow,
  BCol,
  BModal,
  VBModal,
  BSpinner,
  BFormSelect
} from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BTable,
    BButton,
    BAvatar,
    BBadge,
    BForm,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BFormDatepicker,
    BDropdownItem,
    BRow,
    BCol,
    BModal,
    vSelect,
    BSpinner,
    BFormSelect
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      loader: true,
      day: null,
      newuser: {
        team: null,
        email: "",
        prenom: "",
        nom: "",
        aircall: null,
      },
      edituser: {
          data: {
            team: null,
            email: "",
            prenom: "",
            nom: "",
            aircall: null
          }
      },
      filter: {
        from: null
      },
      pageLength: 3,
      dir: false,
      fields: ["email", "nom", "prenom", "teamname", "heuredebut", "heurefin", "pause", "callduration", "engagement"],
      fields: [
        {
          key: "nom",
          label: "Nom",
          sortable: true,
        },
        {
          key: "prenom",
          label: "Prénom",
          sortable: false,
        },
        {
          key: "teamname",
          label: "Equipe",
          sortable: true,
        },
        {
          key: "heuredebut",
          label: "Heure début",
        },
        {
          key: "heurefin",
          label: "Heure fin",
        },
        {
          key: "pause",
          label: "Pause (minutes)",
        },
        {
          key: "callduration",
          label: "Durée appels (heures)",
        },
        {
          key: "engagement",
          label: "Taux d'engagement (%)",
        },
      ],
      columns: [
        {
          label: "Prénom",
          field: "prenom",
        },
        {
          label: "Nom",
          field: "nom",
        },
        {
          label: "Equipe",
          field: "teamname",
        },
        {
          label: "Heure début",
          field: "heuredebut",
        },
        {
          label: "Heure fin",
          field: "heurefin",
        },
        {
          label: "Pause",
          field: "pause",
        },
        {
          label: "Durée en ligne",
          field: "callduration",
        },
        {
          label: "Engagement",
          field: "engagament",
        },
      ],
      statistiques: {
        engagements: [],
        day: null
      },
      searchTerm: "",
    };
  },

  created() {
    this.$http.post($themeConfig.app.restEndPoint + "/statistiques").then((res) => {
      this.$set(this.statistiques, "engagements", res.data.engagements);
      this.$set(this.statistiques, "day", res.data.day);
      this.$set(this.filter, "from", res.data.day);
      setTimeout(() => {
        this.loader = false;
      }, 1000);
    });
  },

  methods: {
    getday() {
        this.$http.post($themeConfig.app.restEndPoint + "/statistiques", {"day": this.filter.from}).then((res) => {
        this.$set(this.statistiques, "engagements", res.data.engagements);
        this.$set(this.statistiques, "day", res.data.day);
        setTimeout(() => {
            this.loader = false;
        }, 1000);
        });
    },
    edit(item) {
      console.log(item);
    },
    add_user() {
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/add", this.newuser).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
            this.newuser = {
                team: null,
                email: "",
                prenom: "",
                nom: "",
                aircall: null,
            }
        });
    },
    upd_user() {
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/upd", this.edituser.data).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
            this.edituser = {
                data: {
                    team: null,
                    email: "",
                    prenom: "",
                    nom: "",
                    aircall: null
                }
            }
        });
    },
    edit_user(user) {
        console.log(user)
        this.$set(this.edituser, "data", user)
        /*
        for (var i=0; i < this.users.aircall_users.length; i++) {
            if (this.users.aircall_users[i].id = this.edituser.data.aircall) {
                //this.edituser.id_aircall = this.users.aircall_users[i].id
                this.$set(this.edituser.data, "aircall", this.users.aircall_users[i])
                break;
            }
        }
        
        for (var i=0; i < this.users.teams.length; i++) {
            if (this.users.teams[i].id_team = this.edituser.data.id_team) {
                this.$set(this.edituser.data, "team", this.users.teams[i].id_team)
                break;
            }
        }
        */
        this.$refs["modal-upd"].show();
    },
    delete_user(user) {
        console.log(user)
        this.loader = true;
        this.$http.post($themeConfig.app.restEndPoint + "/user/delete", {"id_user": user.id_user}).then((res) => {
            this.$set(this.users, "users", res.data.users);
            this.loader = false;
        });
    }
  },
};
</script>
 